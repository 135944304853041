import './bootstrap';
import '../css/app.css';

import {createApp, createSSRApp, h} from 'vue';
import {createInertiaApp, router} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy';
import FloatingVue from 'floating-vue'

import Toast, {POSITION} from "vue-toastification";
import "vue-toastification/dist/index.css";
import 'floating-vue/dist/style.css'
import {PhoneInput} from '@lbgm/phone-number-input';
import '@lbgm/phone-number-input/style';
import VimeoPlayer from 'vue-vimeo-player'
import {StoryblokVue, apiPlugin} from '@storyblok/vue';

const appName = import.meta.env.VITE_APP_NAME || 'Greetmate.ai';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    async setup({el, App, props, plugin}) {

        const options = {
            position: POSITION.BOTTOM_LEFT,
            timeout: 30000
        };

        const app = createSSRApp({render: () => h(App, props)})
            .use(plugin)
            .use(ZiggyVue)
            .use(Toast, options)
            .use(FloatingVue)
            .use(VimeoPlayer)
            .use(StoryblokVue, {
                apiOptions: {
                    region: "us",
                },
                accessToken: import.meta.env.VITE_STORYBLOK_PREVIEW_TOKEN,
                bridge: import.meta.env.NODE_ENV !== 'production', // optimizes by excluding the bridge on production
                use: [apiPlugin],
            })
            .component('PhoneInput', PhoneInput);


        // Storyblok
        // Automatically import all Vue components from the /components directory
        const modules = import.meta.glob('./Components/Storyblok/**/*.vue');

        for (const path in modules) {
            await modules[path]().then((mod) => {
                // Extract the component name from the file path
                const componentName = path
                    // Remove the "./components/" from the beginning
                    .replace('./Components/Storyblok', '')
                    // Remove the file extension from the end
                    .replace(/\.\w+$/, '')
                    // Split up kebabs and slashes
                    .split(/[-/]/)
                    // Convert to PascalCase (CamelCase with the first letter capitalized)
                    .map(part => part.charAt(0).toUpperCase() + part.slice(1))
                    // Concatenate
                    .join('');

                // Globally register the component

                app.component(componentName, mod.default);
            });
        }

        // Finally mount
        app.mount(el);

        // if (window && window.location.hostname !== 'localhost') {
        //     Sentry.init({
        //         dsn: "https://a769717cfc4f879e7f9110464c1a1af0@o4507648266665984.ingest.us.sentry.io/4508130084913152",
        //         integrations: [
        //             Sentry.browserTracingIntegration(),
        //             Sentry.replayIntegration(),
        //             Sentry.feedbackIntegration({
        //                 // Additional SDK configuration goes in here, for example:
        //                 colorScheme: "system",
        //             }),
        //         ],
        //         beforeSend(event, hint) {
        //             // // Check if it is an exception, and if so, show the report dialog
        //             // if (event.exception && event.event_id) {
        //             //     Sentry.showReportDialog({eventId: event.event_id});
        //             // }
        //             return event;
        //         },
        //         // Tracing
        //         tracesSampleRate: 1.0, //  Capture 100% of the transactions
        //         // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        //         // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        //         // Session Replay
        //         replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        //         replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        //     });
        // }

        return app;
    },
    progress: {
        color: '#4B5563',
    },
});

